import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(d => d.AuthModule)
  },
  {
    path: 'client',
    loadChildren: () => import('./client/client.module').then(d => d.ClientModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(d => d.AdminModule)
  },
  {
    path: 'shared',
    loadChildren: () => import('./shared/shared.module').then(d => d.SharedModule)
  },
  {
    path: 'subscriber',
    loadChildren: () => import('./freelancer/freelancer.module').then(d => d.FreelancerModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./pages/pages.module').then(d => d.PagesModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }

