<div class="main">
  
  <div class="sticky-top">
    <!-- NAVBAR -->
    <app-navbar></app-navbar>
  </div>

  <router-outlet></router-outlet>

  <!-- FOOTER -->
  <app-footer></app-footer>
</div>

