import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { PagesModule } from './pages/pages.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from './shared/shared.module';
import { AuthModule } from './auth/auth.module';
import { AppRoutingModule } from './app-routing.module';
import { FreelancerModule } from './freelancer/freelancer.module';
import { AdminModule } from './admin/admin.module';
import { ClientModule } from './client/client.module';
import { CoreModule } from './core/core.module';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HttpErrorInterceptor } from './auth/interceptor/httpconfig.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ViewportScroller } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AuthModule,
    FreelancerModule,
    AdminModule,
    ClientModule,
    PagesModule,
    CoreModule,
    AppRoutingModule,
    FontAwesomeModule,
    SharedModule
  ],
  providers: [
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(router: Router, viewportScroller: ViewportScroller) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
  