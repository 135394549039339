import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NotificationService } from '../../core/services/notification.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../core/services/authentication.service';

/**
 * Intercepts every HTTP request
 */
@Injectable({
  providedIn: 'root',
})

export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * Adds dependencies to the component
   * @param notify - Notification service for displaying toast messages
   * @param router - Router for navigation to other route
   * @param auth - Authentication Service that contains the authentication information
   */
  constructor(
    public notify: NotificationService,
    private auth: AuthenticationService,
    private router: Router
  ) {}
  /**
   * Intercepts every HTTP request and adds the token to those that require authorization
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get('no-auth') === 'true') {
      return next.handle(request);
    }
    
    /**
     * Adds a token to the header of the request
     */
    const tokenizedReq = request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.auth.decrypt(localStorage.getItem('token'))
      }
    });
    /**
     * Handles the next request
     */
    return next.handle(tokenizedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';

        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          // Logouts out user and redirects them to the login page if the request is unauthorized
          if (error.status === 403) {
            this.auth.logout();
            this.router.navigate(['login']);
            this.notify.showInfo('Your session has expired. Login again to continue.');
          } else {
            errorMessage = 'An error has occured. Check your internet connection.';
          }
        }

        return throwError(errorMessage);
      })
    );
  }
}
